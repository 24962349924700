import React from 'react';
import { Container } from './Container';

import css from './Main.module.css';

export const Main = ({ children }) => (
  <main role="main" id="main" tabIndex="-1" className={css.main}>
    <Container>{children}</Container>
  </main>
);
