import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '../utils/useSiteMetadata';

export const Meta = ({ title, description, pathname }) => {
  const siteMetadata = useSiteMetadata();

  const meta = {
    title: title || siteMetadata.title,
    description: description || siteMetadata.description,
    image: `${siteMetadata.url}/${siteMetadata.image}`,
    type: 'website',
    url: pathname ? `${siteMetadata.url}/${pathname}` : siteMetadata.url,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={`${siteMetadata.title}, ${siteMetadata.subtitle}`}
      titleTemplate={`%s | ${siteMetadata.title}`}
    >
      <html lang="sk" />

      {/* Primary Meta Tags */}
      {/* <meta name="google-site-verification" content="..." /> */}
      <meta name="description" content={meta.description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:url" content={meta.url} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={meta.image} />

      <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
      <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />

      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;900&display=swap" rel="stylesheet" />
    </Helmet>
  );
};
